import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

interface State {
  count: number;
  list: [];
  api: any;
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: 20 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Nemesys
      </Link>{' '}
      {new Date().getFullYear()}
      {'. Built with '}
      <Link color="inherit" href="https://material-ui.com/">
        Material-UI.
      </Link>
    </Typography>
  );
}

export const Todo = (props: any) => {
  const [state, setData] = useState<State>({ count: 22, list: [], api: {} });

  const addItem = async () => {
    const response = await fetch('/prod/turbolambda/add', {
      method: 'POST',
      body: JSON.stringify({ item: (document as any).getElementById('add').value }),
    });
    const result = (await response.json()) as any;
    console.log('GOT', result);
    setData({ ...state, list: result.data.list, api: result });
  };

  useEffect(() => {
    (async () => {
      let result: any = {};
      console.log('Load list');
      try {
        const response = await fetch('/prod/turbolambda/list', { method: 'POST' });
        result = (await response.json()) as any;
      } catch (e) {
        console.log('Error loading', e);
      }
      setData({ ...state, list: result.data.list, api: result });
    })();
  }, [1]);

  return (
    <>
      <div>
        <div id="todo" style={{ marginTop: '20px', marginBottom: '20px', minHeight: 150 }}>
          {state.list.map((m, i) => (
            <div style={{ textAlign: 'center', background: i % 2 == 0 ? '#ccc' : ' white' }} key={i}>
              {m}
            </div>
          ))}
        </div>
        <div style={{ textAlign: 'center' }}>
          <input id="add" />
          <button onClick={() => addItem()}>Add</button>
        </div>
      </div>
      <Copyright />
      <pre style={{ marginTop: '600px' }}>{JSON.stringify(state.api, null, 2)}</pre>
    </>
  );
};
